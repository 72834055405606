import firebase from 'firebase'
import "firebase/storage"

require('dotenv').config();



const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const database = firebase.database();

  const auth = firebase.auth();

  export const storage  = firebase.storage();

  export {auth, database};
