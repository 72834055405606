import Navbar from "../Components/navbar";

export const HomeEvaluation = () =>{

    return(
        <div>
        <Navbar></Navbar>
            <h1 style={{marginTop: "5%", textAlign: "center"}}>WORK IN PROGRESS</h1>
        </div>
    )

}