import React from 'react'
import MyBookingsSection from '../Components/MyBookingsSection'
import  Navbar from '../Components/navbar'



export default function MyBookings() {
    return (
        <div>
           <Navbar/>
           <MyBookingsSection/>
        </div>
    )
}
